export const initializeAnimatedLogo = () => {
    const logoAnimatedSvgElement = document.querySelector<SVGElement>('.navigation-logo-animated')
    if (logoAnimatedSvgElement) {
        const addAnimatedRestartHandler = (element: SVGElement) => {
            element.addEventListener("click", () => {
                const duplicatedElement = <SVGElement>element.cloneNode(true)
                if (!element.parentNode) return
                element.parentNode.replaceChild(duplicatedElement, element)
                addAnimatedRestartHandler(duplicatedElement)
            }, { once: true })
        }
        addAnimatedRestartHandler(logoAnimatedSvgElement)
    }
}
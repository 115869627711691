import { initializeAnimatedLogo } from "./animatedLogo"
import { initializeMailToLink } from "./mailToLink"
import { initializeTextAndPaper } from "./textAndPaper"

document.addEventListener("DOMContentLoaded", () => {
    const isRPG = document.body.classList.contains("rpg")
    if (isRPG) {
        const empty = ""
        import("/scripts/" + "rpg.js" + empty).then(textAndPaper => {
            console.clear()
            textAndPaper.start()
        })
    } else {
        initializeAnimatedLogo()
        initializeTextAndPaper()
        initializeMailToLink()
    }

})

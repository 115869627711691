export const initializeMailToLink = () => {
    for (const mailToLink of document.querySelectorAll<HTMLAnchorElement>('a[href^="mailto:"]')) {
        const mail = mailToLink.href.replace("mailto:", "").trim()
        if (!mail) continue

        mailToLink.addEventListener("click", (event) => {
            event.preventDefault();
            navigator.clipboard.writeText(mail)

            mailToLink.classList.add("copied")
            setTimeout(() => mailToLink.classList.remove("copied"), 1500)
        })
    }
}